import React, { Fragment, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faSearchPlus, 
  faSearchMinus, 
  faLock 
} from '@fortawesome/free-solid-svg-icons'
import { useMediaQuery } from '@material-ui/core';
import json2mq from 'json2mq';
import { shortQueries } from '../../queries';
import "./saveButton.css";
import { Strings } from '../../App';

var url = (window.location != window.parent.location)
            ? document.referrer
            : document.location.href;

url = url.replace(/^https?:\/\//,'https://');
/*
  This component renders custom control buttons over the
  estimator's interactive Google Map.

  This component takes the following props:

  * color
  * handleZoomInButton
  * handleZoomOutButton
  * handleLockMapButton
*/

export default function MapControls(props) {

  const short = useMediaQuery(json2mq(shortQueries));

  // Build styles for buttons
  const buttonStyle = {
    width: short ? "40px" : "50px",
    height: short ? "40px" : "50px",
    backgroundColor: props.color.button,
    border: "none",
    borderRadius: short ? "20px" : "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: short ? "20px" : "25px",
    color: props.color.iconActive,
    outline: 'none',
    cursor: 'pointer'
  };

  const strings = useContext(Strings)
  return (
    <Fragment >
       <link rel="stylesheet" type="text/css" href={`${url}measur.it/styles.css`} />
      {/* Top Controls */}
      <div
        className="map-tools-lock"
        style={{
          position: "absolute",
          bottom:  '8%', //short ? "80px" : "35px",
          zIndex: 99,
          display: 'flex',
          // right: 30,
          // width: 300,
          justifyItems: 'flex-end'
        }}
      >
        <button 
          aria-label="Lock map"
          type="button" 
          onClick={props.handleLockMapButton}
          //style={buttonStyle}
          className="lock-button"
        >
          
          {/*<FontAwesomeIcon icon={faLock} />*/}
          Start Measuring
            <img className="measure-logo" src="./Measureit.png" alt={faLock} />
        </button>
      </div>
      {/* Bottom Controls */}
      <div 
        style={{
          position: "absolute",
          bottom:  '35%', //short ? "200px" : "160px",
          right: 30,
          // width: 300,
          display: "flex",
          flexDirection: "column",
          zIndex: 99
        }}
        className="map-tools-zoom"
      >
        <button 
          aria-label="Zoom in"
          type="button" 
          onClick={props.handleZoomInButton}
          style={{
            ...buttonStyle,
            marginBottom: "10px"
          }}
        >
          <FontAwesomeIcon icon={faSearchPlus} />
        </button>
        <button 
          aria-label="Zoom out"
          type="button" 
          onClick={props.handleZoomOutButton}
          style={buttonStyle}
        >
          <FontAwesomeIcon icon={faSearchMinus} />
        </button>
      </div>
    </Fragment>
  );
}
