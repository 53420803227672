import React, { Fragment, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faPaintBrush, 
  faEraser, 
  faUndoAlt,
  faTrashAlt,
  faCircle, 
  faUnlock,
  faTimes,
  faSave,
  faQuestion,
  faCheck
} from '@fortawesome/free-solid-svg-icons'
import { useMediaQuery } from '@material-ui/core';
import json2mq from 'json2mq';
import { shortQueries } from '../../queries';
import "./saveButton.css";

/*
  This component renders custom control buttons over the
  estimator's HTML canvas for perimeter calculation.

  This component takes the following props:

  * color
  * handleUndoButton
  * handleClearButton
  * handleUnlockMapButton
  * handleSaveButton
*/

export default function PerimeterDrawControls(props) {


  const short = useMediaQuery(json2mq(shortQueries));
  const [showSaveButton, setShowSaveButton] = useState(false);


  const enableSaveButton = () => {
    if (props.clientOptions.disableSave === false) {
      setShowSaveButton(true);
    } else {
      (props.currentMeasurement > props.clientOptions.min && props.clientOptions.max > props.currentMeasurement) ? setShowSaveButton(true) : setShowSaveButton(false);
    }
  }

  useEffect( enableSaveButton, [props.currentMeasurement, props.clientOptions] )


  // Build styles for buttons
  const buttonStyle = {
    width: short ? "40px" : "50px",
    height: short ? "40px" : "50px",
    backgroundColor: props.color.button,
    border: "none",
    borderRadius: short ? "20px" : "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: short ? "20px" : "25px",
    color: props.color.iconActive,
    outline: 'none',
    cursor: 'pointer'
  };

  const saveButton = (
    <div 
      style={{
        position: "absolute",
        bottom:  '8%', //short ? "80px" : "35px",
        right: "10px",
        zIndex: 99
      }}
      className="draw-tools-save-button"
    >
    
      <button 
        aria-label="Save"
        type="button" 
        onClick={props.handleSaveButton}
        //style={buttonStyle}
        className="save-button"
      >
        {/*<FontAwesomeIcon icon={faCheck} /> */}
        Get My Quote ✅
      </button>
    </div> )

var url = (window.location != window.parent.location)
? document.referrer
: document.location.href;
url = url.replace(/^https?:\/\//,'https://');


  return (
    <Fragment>
        <link rel="stylesheet" type="text/css" href={`${url}measur.it/styles.css`} />
      {/* Top Controls */}
      <div
        style={{
          position: "absolute",
          top: short ? "15px" : "20px",
          left: "10px",
          zIndex: 99
        }}
      >
        <button 
          aria-label="Unlock map"
          type="button" 
          onClick={props.handleUnlockMapButton}
          className="go-back-button"
          //style={{...buttonStyle, ...{width: short ? 20 : 30, height: short? 20 : 30, borderRadius: short ? 15 : 15, fontSize: short ? 15 : 20}}}
        >
          {/* <FontAwesomeIcon icon={faTimes} /> */}
         Go Back
        </button>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: short ? "200px" : "160px",
          right: "20px",
          display: "flex",
          flexDirection: "column",
          zIndex: 99
        }}
        className="draw-tools-back-undo"
      >
        <button 
          aria-label="Undo"
          type="button" 
          onClick={props.handleUndoButton}
          style={{
            ...buttonStyle,
            marginBottom: "10px"
          }}
        >
          <FontAwesomeIcon icon={faUndoAlt} />
        </button>
        <button 
          aria-label="Clear"
          type="button" 
          onClick={props.handleClearButton}
          style={buttonStyle}
          type="button" 
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </div>
      {/* Bottom Controls */}
      <div
        style={{
          position: "absolute",
          bottom: "8%",
          right: "10px",
          zIndex: 99
        }}
      >
        { showSaveButton ? saveButton : null}
      </div>
    </Fragment>
  );

}