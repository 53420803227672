import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Strings } from '../../App';

export default function AlertDialog(props) {
    

  const strings = useContext(Strings)

  const { tourPages, pageLogo } = strings || {}
  const { detectedInaccurate } = tourPages || {}
  const {
      heading,
      image,
      message,
      buttons
  } = detectedInaccurate || {}

  const { submitText, continueEstimating } = buttons || {}

    const handleClose = () => {
      props.setWarningOpen(false);
    };

    const proceedEstimate = () => {
      props.proceedEstimate()
    }
  
    return (
      <div>
        <Dialog
          open={props.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{heading ? heading : "Oops! Does your estimate look right?"}</DialogTitle>
          <DialogContent>
            <img src={image ? image.url : "/mobilerightwrong2.png"}  style={{width: image ? image.width : '78%', height: image && image.height ? image.height : 100}}/> 
            <DialogContentText id="alert-dialog-description" style={{fontSize: 15}}>
              {message ? message : <span>
                We will only service the areas that are <strong>COLORED IN</strong> and not just outlined. Your <strong>COLORED IN</strong> image is saved and we will only service <strong>COLORED IN</strong> areas on the picture
              </span> }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={proceedEstimate} color="primary" >
              {submitText ? submitText :  'Submit Estimate'}
            </Button>
            <Button onClick={handleClose} color="primary" autoFocus>
              {continueEstimating ? continueEstimating :  'Continue Coloring'}
            </Button>
            
          </DialogActions>
        </Dialog>
      </div>
    );
  }