import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types'
import { useMediaQuery } from '@material-ui/core';
import json2mq from 'json2mq';
import { shortQueries } from '../queries';
import { Strings } from '../App';

const buttonStyle = {
    padding: 10,
    position: 'relative',
    float: 'right',
    background: '#2BE396',
    borderRadius: 10,
    minWidth: 70,
    outline: 'none',
    cursor: 'pointer',
    fontWeight: 'bold'
}

const textStyle = {
    fontFamily: "'Open Sans', sans-serif",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
}


export const MeasurItWelcome = (props) => {
    
    const strings = useContext(Strings)
    const {
        loadingLogo,
        tourPages
    } = strings

    const {welcome} = tourPages || {}

    const {
        companyName,
        heading,
        message1,
        message2,
        buttonText
    } = welcome || {}

    const short = useMediaQuery(json2mq(shortQueries));

    const [logo, setLogo] = useState( (short ? "/measur.it-intro-small.png" : "/measur.it-intro.png"));
    // const [companyName, setCompanyName] = useState("Measur.it");
    const [lineOne, setLineOne] = useState(" is an easy way to measure online.");
    const [lineTwo, setlineTwo] = useState("If you can use a paint brush, you can measure your lawn.")
    const [title, setTitle] = useState("Estimate Your Lawn");
    

    const getDefaultLogo = () => {
        return (short ? "/measur.it-intro-small.png" : "/measur.it-intro.png")
    }
    
    return (<div style={textStyle}>
        <h3>{heading || title}</h3>

        <img className='welcome-image' src={loadingLogo ? loadingLogo.url : getDefaultLogo()}  style={{width: loadingLogo && loadingLogo.width ? loadingLogo.width: 100, height: loadingLogo && loadingLogo.height ? loadingLogo.height : 100, alignSelf: 'center'}} />
        <br />
        <p style={short ? {width: 225, fontSize: 14} : {}}>
            <span className='company-name' style={{ fontWeight: 'bold', color: '#2BE396'}}>{companyName || 'Measur.it'}</span> {message1 || lineOne}  
            <br/><br/>{message2 || lineTwo}
        </p>
        <button className='action-button' onClick={() => props.goTo(1)} style={buttonStyle}>{buttonText || 'Start'}</button>
    </div>)
}

MeasurItWelcome.propTypes = {
    goTo: PropTypes.func.isRequired
}