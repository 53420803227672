// Data and settings passed into the project per client requirements
// import { apiKey } from './apiKey.js';

// Starting location
// This is a LatLng object with lat and lng keys
export const startingLocation = {
  lat: 42.924510,
  lng: -85.596830
};

// Starting zoom level for Google Maps API
// https://developers.google.com/maps/documentation/javascript/tutorial
export const startingZoomLevel = 20;

// Google Maps API Key
// Stored in separate non-repo file
export const GOOGLE_API_KEY = process.env.GOOGLE_API_KEY || 'AIzaSyCsvXcHXmswWZAi443G53uNl11Q7Pfxn0s';

// Component mode - a string value
// Possible options include "area" and "perimeter"
export const componentMode = "area"

export const parentURL = (window.location != window.parent.location)
            ? document.referrer
            : document.location.hostname;